<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useHead } from '@unhead/vue';

const mounted = ref(false);
onMounted(() => (mounted.value = true));

useHead({
  title: 'Студия Анонима. Разрабатываем программы и приложения',
  meta: [{ name: 'description', content: 'Разрабатываем программы и приложения' }],
});
</script>

<template>
  <div class="a-landing">
    <div class="a-logo">
      <img class="a-logo__image" src="~/assets/images/logo.svg" alt="Anonima Studio" />
      <span class="a-logo__text">Студия Ано́нима</span>
    </div>
    <h2>Разрабатываем программы и&nbsp;приложения.</h2>
    <p>Полный цикл разработки без лишних ролей и&nbsp;бюрократии.</p>
    <p>Дизайн, который решает задачи.</p>
    <p>Реактивный интерфейс.</p>
    <p>Актуальные технологии.</p>
    <p>Соблюдение правильных стандартов.</p>
    <p>Архитектура проекта, учитывающая бизнес-потребности и&nbsp;возможность развития.</p>
    <div class="-mt-4">
      <a
        v-if="mounted"
        class="a-button _primary _large"
        href="mailto:studio@anonima.ru?subject=%D0%A0%D0%B0%D0%B7%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B0%20%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%B0"
      >
        Обсудить проект
      </a>
    </div>
  </div>
</template>
