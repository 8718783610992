import components_plugin_KR1HBZs4kY from "/vercel/path0/packages/ssr/.nuxt/components.plugin.mjs";
import unhead_neSs9z3UJp from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NWDNuRANbU from "/vercel/path0/node_modules/nuxt/dist/app/plugins/router.js";
import chunk_reload_client_SeG0EjL5Ec from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_VTarsGAzgb from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
export default [
  components_plugin_KR1HBZs4kY,
  unhead_neSs9z3UJp,
  router_NWDNuRANbU,
  chunk_reload_client_SeG0EjL5Ec,
  payload_client_VTarsGAzgb
]