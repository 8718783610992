import { useDebounceFn } from '@vueuse/shared';

/**
 * Fix `vh` in iOS.
 */
export function fixVh() {
  function calc() {
    window.requestAnimationFrame(() => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }

  calc();
  window.addEventListener('resize', useDebounceFn(calc, 100));
}
