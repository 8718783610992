<script lang="ts">
import MainPage from '~/components/MainPage.vue';
import { defineComponent } from 'vue';
import { fixVh } from '~/composables';
// Transforms to `<link rel="preload">` and `<link rel="stylesheet">`,
// imported `url()` in CSS will be transformed to `<link rel="prefetch" >`.
import './assets/styles/index.scss';

export default defineComponent({
  components: {
    MainPage,
  },
  mounted() {
    fixVh();
  },
});
</script>

<template>
  <MainPage />
</template>
